import React, { FC, useState, CSSProperties, useContext } from 'react';
import {
    HeaderIconStyled,
    HeaderLogoStyled,
    HeaderWrapperStyled,
    ToggleVisibilityWrapperStyled,
} from './Header.styled';
import { LogoIcon } from '../icons/Logo/Logo';
import { MenuIcon } from '../icons/Menu/Menu';
import { ChevronUpIcon } from '../icons/ChevronUp/ChevronUp';
import { ProductCountBasket } from './ProductCountBasket/ProductCountBasket';
import Link from 'next/link';
import { Menu } from '../Menu/Menu';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { getCartItemsCount } from '../../store/project/selectors';
import { getPublicEnv } from '../../services/env/env';
import { useHomePageUrl } from '../../hooks/useHomePageUrl';
import { cartContext } from '../CartSidePanel/CartSidePanelProvider';
import { Lang } from '../Lang/Lang';
import { CurrencyHeader } from '../Currency/CurrencyHeader';
import { useEnv } from '../../hooks/useEnv';
import { MeasureUnitHeader } from '../MeasureUnit/MeasureUnitHeader';

const Auth = dynamic(
    async () => {
        const accountIsActive = (await getPublicEnv('FEAT_FLAG_ACCOUNT')) === '1';

        if (accountIsActive) {
            return import('../Auth/Auth');
        }

        return import('../Auth/AuthOldWay');
    },
    {
        ssr: false,
        loading: () => <HeaderIconStyled />,
    },
);

interface HeaderProps {
    isVisible: boolean;
    transparent?: boolean;
    handleVisbilityChanged?: () => void;
    position?: CSSProperties['position'];
    shouldHideCartWhenHaveItemsInside?: boolean;
}

export const Header: FC<HeaderProps> = ({
    isVisible,
    handleVisbilityChanged,
    transparent,
    position,
    shouldHideCartWhenHaveItemsInside = false,
}) => {
    const [showMenu, setMenuVisibility] = useState(false);
    const { isMobile } = useBreakpoints();
    const homepage = useHomePageUrl();
    const FEAT_FLAG_MENU = useEnv('FEAT_FLAG_MENU');
    const [isInitializedHeader, setIsInitializedHeader] = useState(isVisible);
    const [isInitializedMenu, setIsInitializedMenu] = useState(false);

    return (
        <>
            <HeaderWrapperStyled isVisible={isVisible} transparent={transparent} position={position} data-test="header">
                <div>
                    {FEAT_FLAG_MENU === '1' && (
                        <HeaderIconStyled
                            data-test-id="hamburger_menu_icon"
                            onClick={() => setMenuVisibility(true)}
                            onMouseEnter={() => {
                                setIsInitializedMenu(true);
                            }}
                        >
                            <MenuIcon />
                        </HeaderIconStyled>
                    )}
                    {handleVisbilityChanged && !isMobile && (
                        <ToggleVisibilityWrapperStyled
                            onClick={(e) => {
                                e.stopPropagation();
                                handleVisbilityChanged();
                            }}
                            onMouseEnter={() => {
                                setIsInitializedHeader(true);
                            }}
                            isVisible={isVisible}
                        >
                            <div className="pointer-events-none">
                                <ChevronUpIcon />
                            </div>
                        </ToggleVisibilityWrapperStyled>
                    )}
                </div>
                {isInitializedHeader && (
                    <Link href={homepage} passHref prefetch={false} legacyBehavior>
                        <HeaderLogoStyled as="a">
                            <LogoIcon />
                        </HeaderLogoStyled>
                    </Link>
                )}
                {isInitializedHeader && (
                    <HeaderContent
                        transparent={transparent}
                        shouldHideCartWhenHaveItemsInside={shouldHideCartWhenHaveItemsInside}
                    />
                )}
            </HeaderWrapperStyled>
            {FEAT_FLAG_MENU === '1' && isInitializedMenu && (
                <Menu isOpen={showMenu} onClose={() => setMenuVisibility(false)} />
            )}
        </>
    );
};

const HeaderContent: FC<Pick<HeaderProps, 'transparent' | 'shouldHideCartWhenHaveItemsInside'>> = ({
    transparent,
    shouldHideCartWhenHaveItemsInside = false,
}) => {
    const cartItemsCount = useSelector(getCartItemsCount);
    const showCart = !shouldHideCartWhenHaveItemsInside || (shouldHideCartWhenHaveItemsInside && cartItemsCount === 0);
    const { openCart } = useContext(cartContext);

    return (
        <div className="flex justify-end">
            <div>
                <Lang transparent={transparent} />
            </div>
            <div>
                <CurrencyHeader transparent={transparent} />
            </div>
            <div>
                <MeasureUnitHeader transparent={transparent} />
            </div>
            <div>
                <Auth />
            </div>
            <div>
                {showCart && (
                    <span
                        onClick={() => {
                            openCart();
                        }}
                    >
                        <HeaderIconStyled as="a">
                            <ProductCountBasket />
                        </HeaderIconStyled>
                    </span>
                )}
            </div>
        </div>
    );
};
